<template>
  <b-overlay
    :show="loading"
    rounded
    opacity="0.6"
    spinner-small
    spinner-variant="primary"
  >
    <b-card>
      <b-row>
        <!-- User Info: Left col -->
        <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="imagePreview || userData.avatar"
              :text="userData.avatar ? userData.avatar : avatarText(userData.name)"
              :variant="`light-primary`"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ `${userData.name} ${userData.last_name}` }}
                </h4>
                <span class="card-text">{{ userData.email }}</span>
              </div>
              <div class="d-flex flex-wrap">
                <b-button
                  variant="primary"
                  size="sm"
                  @click="$refs.refAvatarMain.click()"
                >
                  <input
                    ref="refAvatarMain"
                    type="file"
                    class="d-none"
                    @change="handleFileChange($event)"
                  />
                  <feather-icon icon="UploadIcon" class="d-inline mr-sm-50" />
                  <span class="d-none d-sm-inline">Subir Foto</span>
                </b-button>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100">
            <tr v-if="userData.age">
              <th class="pb-50">
                <feather-icon icon="StarIcon" class="mr-75" />
                <span class="font-weight-bold">Edad</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ `${userData.age} años` }}
              </td>
            </tr>
            <tr v-if="userData.status">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">Estatus</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.status ? "Activo" : "Inactivo" }}
              </td>
            </tr>
            <tr v-if="userData.roles">
              <th class="pb-50">
                <feather-icon icon="StarIcon" class="mr-75" />
                <span class="font-weight-bold">Rol</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.roles[0].label }}
              </td>
            </tr>
            <tr v-if="userData.grado">
              <th class="pb-50">
                <feather-icon icon="FlagIcon" class="mr-75" />
                <span class="font-weight-bold">Grado</span>
              </th>
              <td class="pb-50">
                {{ userData.grado }}
              </td>
            </tr>
            <tr v-if="userData.aula">
              <th>
                <feather-icon icon="HashIcon" class="mr-75" />
                <span class="font-weight-bold">Aula</span>
              </th>
              <td>
                {{ userData.aula }}
              </td>
            </tr>
            <tr v-if="userData.celular">
              <th>
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">Celular:</span>
              </th>
              <td>
                {{ userData.celular }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon icon="StarIcon" class="mr-75" />
                <span class="font-weight-bold">Colegio:</span>
              </th>
              <td>
                {{ userData.school.name }}
              </td>
            </tr>
          </table>
        </b-col>

        <!-- crear un hr y luego poner la seccion de observaciones -->
        <b-col cols="12">
          <hr />
        </b-col>

        <b-col cols="12">
          <div class="d-flex justify-content-between align-items-start">
            <div>
              <b-card-title>Observación</b-card-title>
            </div>
            <div>
              <b-button
                :variant="userData.observation ? 'outline-warning' : 'outline-primary'"
                @click="modalAddObservation" 
                size="sm">
                <feather-icon :icon="userData.observation ? 'EditIcon' : 'PlusIcon'" />
              </b-button>
            </div>
          </div>

          <b-row>
            <b-col cols="12">
              {{ userData.observation || "No hay observaciones" }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <!-- data de los hijos -->
    <b-modal
      v-model="showModalRestrictProducts"
      centered
      :title="'Lista de Productos Restringidos'"
      hide-footer
      no-close-on-backdrop
      size="xl"
    >
      <b-overlay
        :show="loading"
        rounded
        responsive
        variant="transparent"
        opacity="0.33"
        blur="2px"
      >
        <div>
          <div class="row">
            <div class="col-12">
              <h4>Seleccione un hijo</h4>
              <div class="d-flex flex-row">
                <div v-for="child in childs" :key="child.id" class="mr-4">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="selectedChildId"
                      :value="child.id"
                      :name="child.name"
                      :unchecked-value="false"
                      @change="handleCheckboxChange($event, child.id)"
                    >
                      {{ child.name + " " + child.last_name }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>

              <b-form @submit.prevent="searchProduct">
                <b-form-group label="Buscar Producto:">
                  <b-form-group>
                    <b-form-input
                      v-model="searchProductData.product"
                      placeholder="Buscar producto"
                    />
                  </b-form-group>
                  <b-button type="submit" variant="primary"> Buscar </b-button>
                </b-form-group>
              </b-form>

              <!-- Tabla de productos Nuevos -->
              <div v-if="isMobileView">
                <!-- Vista móvil con tarjetas -->
                <div v-if="productsNew.length > 0">
                  <h4>Productos Disponibles</h4>
                  <div v-for="product in productsNew" :key="product.id" class="mb-3">
                    <b-card>
                      <img
                        :src="product.image"
                        alt="Product Image"
                        class="img-fluid mb-1"
                        top
                      />
                      <b-card-body>
                        <b-card-title>{{ product.name }}</b-card-title>
                        <b-card-text>
                          {{ product.description }}
                          <div>
                            Precio:
                            {{ product.price | currency({ symbol: "$", precision: 2 }) }}
                          </div>
                        </b-card-text>
                        <b-button variant="success" @click.prevent="addProduct(product)">
                          Agregar
                        </b-button>
                      </b-card-body>
                    </b-card>
                  </div>
                </div>
              </div>

              <div v-else>
                <div v-if="productsNew.length > 0">
                  <h4>Productos Disponibles</h4>
                  <b-row>
                    <b-col
                      v-for="product in productsNew"
                      :key="product.id"
                      sm="12"
                      md="6"
                      lg="4"
                      class="d-flex justify-content-center mt-2"
                    >
                      <b-card class="ecommerce-card">
                        <b-row>
                          <b-col class="text-center">
                            <div class="text-truncate">
                              <h4 class="mb-1">{{ product.name }}</h4>
                            </div>
                            <b-col>
                              <img
                                :src="product.image"
                                alt="Product Image"
                                width="150"
                                height="150"
                                class="mb-1"
                              />
                            </b-col>
                            <b-col class="text-truncate">
                              Descripción: {{ product.description }}
                            </b-col>
                            <b-col class="text-truncate">
                              Precio:
                              {{
                                product.price | currency({ symbol: "$", precision: 2 })
                              }}
                            </b-col>
                            <b-col class="text-truncate">
                              Bs:
                              {{
                                ($store.state.bank.data.amount * product.price)
                                  | currency({ symbol: "VEF", precision: 2 })
                              }}
                            </b-col>
                            <b-button
                              class="mt-1"
                              variant="success"
                              @click.prevent="addProduct(product)"
                              block
                            >
                              Agregar
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                  </b-row>
                </div>

                <!-- Tabla de productos -->
                <div v-if="products.length > 0">
                  <h4>Productos Restringidos</h4>
                  <b-row>
                    <b-col
                      v-for="product in products"
                      :key="product.id"
                      sm="12"
                      md="6"
                      lg="4"
                      class="d-flex justify-content-center mt-2"
                    >
                      <b-card class="ecommerce-card">
                        <b-row>
                          <b-col class="text-center">
                            <div class="text-truncate">
                              <h4 class="mb-1">{{ product.name }}</h4>
                            </div>
                            <b-col>
                              <img
                                :src="product.image"
                                alt="Product Image"
                                width="150"
                                height="150"
                                class="mb-1"
                              />
                            </b-col>
                            <b-col class="text-truncate">
                              Descripción: {{ product.description }}
                            </b-col>
                            <b-col class="text-truncate">
                              Precio:
                              {{
                                product.price | currency({ symbol: "$", precision: 2 })
                              }}
                            </b-col>
                            <b-col class="text-truncate">
                              Bs:
                              {{
                                ($store.state.bank.data.amount * product.price)
                                  | currency({ symbol: "VEF", precision: 2 })
                              }}
                            </b-col>
                            <b-button
                              class="mt-1"
                              variant="danger"
                              @click.prevent="deleteProduct(product)"
                              block
                            >
                              Borrar
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
    </b-modal>
    <b-modal
      v-model="showModal"
      centered
      :title="'Vinculación de hijos'"
      hide-footer
      no-close-on-backdrop
    >
      <b-overlay
        :show="loading"
        rounded
        responsive
        variant="transparent"
        opacity="0.33"
        blur="2px"
      >
        <validation-observer ref="rechargeForm">
          <div class="row">
            <div class="col-12">
              <v-select
                multiple
                :loading="loadingUsers"
                @input="saveMergeChilds(userData.id)"
                v-model="childs2"
                @search="searchUsers"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="allUsers"
                label="name"
                placeholder="Buscar Usuario"
                :reduce="(item) => item"
                :filterable="false"
                :close-on-select="true"
              >
                <template v-slot:option="option">
                  {{ option.name }} {{ option.last_name }} -
                  {{ option.school.name }}
                </template>
              </v-select>
            </div>
          </div>

          <!-- <footer class="modal-footer p-0">
            <b-button variant="primary" @click="saveMergeChilds(userData.id)">
              Agregar
            </b-button>
          </footer> -->
        </validation-observer>
      </b-overlay>
    </b-modal>

    <b-modal
      v-model="addNoteChildModel"
      centered
      :title="'Agregar Nota'"
      hide-footer
      no-close-on-backdrop
    >
      <b-overlay
        :show="loading"
        rounded
        responsive
        variant="transparent"
        opacity="0.33"
        blur="2px"
      >
        <div>
          <div class="row">
            <div class="col-12">
              <b-form @submit.prevent="addNoteChildMethod">
                <b-form-group label="Nota:">
                  <b-form-group>
                    <b-form-input v-model="addNoteChild" />
                  </b-form-group>
                  <b-button type="submit" variant="primary"> Guardar </b-button>
                </b-form-group>
              </b-form>
            </div>
          </div>
        </div>
      </b-overlay>
    </b-modal>

    <b-modal
      v-model="showModalAddObservation"
      centered
      :title="(isEditObservation ? 'Editar' : 'Agregar') + ' observación al Perfil'"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <b-overlay
        :show="loading"
        rounded
        responsive
        variant="transparent"
        opacity="0.33"
        blur="2px"
      >
        <div class="row">
          <div class="col-12">
            <b-form @submit.prevent="addObservation">
              <b-form-group
                :label="(isEditObservation ? 'Editar' : 'Agregar') + ' mensaje:'"
              >
                <b-form-group>
                  <b-form-input v-model="observation" />
                </b-form-group>
                <b-button type="submit" variant="primary">
                  {{ isEditObservation ? "Editar" : "Agregar" }}
                </b-button>
              </b-form-group>
            </b-form>
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BOverlay,
  VBModal,
  BFormInput,
  BFormGroup,
  BCardTitle,
  BForm,
  BFormCheckbox,
  BTable,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { localize } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
localize("es", es);
import DatePicker from "vue2-datepicker";
import RechargeMode from "./RechargeMode";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/es";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BOverlay,
    VBModal,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    DatePicker,
    BCardTitle,
    BForm,
    BFormCheckbox,
    BTable,
    RechargeMode,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "image", label: "Imagen" },
        { key: "name", label: "Nombre" },
        { key: "description", label: "Descripción" },
        { key: "price", label: "Precio" },
        { key: "convertedPrice", label: "BS" },
        { key: "actions", label: "Acciones" },
      ],
      imagePreview: null,
      imagePreviews: {},
      selectedChildId: null,
      observation: "",
      copyItemText: "",
      showBank: false,
      dataBanks: [],
      disabled: true,
      required,
      showModal: false,
      showModalRestrictProducts: false,
      showModalAddObservation: false,
      showModalCheck: false,
      selectedProducts: [],
      childSelected: [],
      products: [],
      searchProductData: {
        product: null,
      },
      formData: {
        name: null,
        lastname: null,
        birthday: null,
      },
      childsNew: [],
      loading: false,
      modeEdit: false,
      childs: [],
      childs2: [],
      productsNew: [],
      selectedUser: [],
      loadingUsers: false,
      allUsers: [],
      searchTimeout: null,
      isMobileView: false,
      addNoteChildModel: false,
      isEditObservation: false,
      addNoteChild: "",
      ChildId: "",
    };
  },
  filters: {
    avatarText,
  },
  setup() {
    return {
      avatarText,
    };
  },
  mounted() {
    this.childs = this.userData.childs;
    window.addEventListener("resize", this.checkMobileView);
    this.checkMobileView();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkMobileView);
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL + "api/download/wallet/" + this.userData.id;
    },
    isCashier() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "cashier"
      );
    },
    isAdmin() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "admin"
      );
    },
    isParent() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "representative"
      );
    },
  },
  methods: {
    async addNoteChildMethod() {
      this.loading = true;
      await this.$store
        .dispatch("user/addNoteChild", {
          childId: this.ChildId,
          data: { note: this.addNoteChild },
        })
        .then(async (res) => {
          this.loading = false;
          this.addNoteChildModel = false;
          this.addNoteChild = "";
          this.userData.observation = res.observation;
          const userData = await this.$store.dispatch(
            "user/showUser",
            this.$route.params.id
          );
          this.childs = userData.childs;
          this.message("Nota", "checkCircleIcon", "success", "Agregada con exito");
        })
        .catch(() => {
          this.message("Nota", "AlertCircleIcon", "danger", "Falló en la carga");
          this.loading = false;
        });
    },
    addNoteChildModelOpen(child) {
      this.ChildId = child.id;
      this.addNoteChildModel = true;
    },
    async deleteChild(childId) {
      await this.$store
        .dispatch("user/deleteChild", {
          childId: childId.id,
          data: {
            userId: this.$route.params.id,
          },
        })
        .then((res) => {
          this.loading = false;
          this.childs = res.data.childs;
        })
        .catch(() => {
          this.message("Busquedad", "AlertCircleIcon", "danger", "Falló en la busqueda");
          this.loading = false;
        });
    },
    checkMobileView() {
      // this.isMobileView = window.innerWidth < 768;
    },
    async searchUsers(search) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(async () => {
        if (search && search.length > 2) {
          await this.$store
            .dispatch("user/searchUsers", {
              search: search,
            })
            .then((res) => {
              this.loadingUsers = false;
              this.allUsers = res;
            })
            .catch(() => {
              this.message(
                "Busquedad",
                "AlertCircleIcon",
                "danger",
                "Falló en la busqueda"
              );
              this.loadingUsers = false;
            });
        }
      }, 500); // 500ms de retraso
    },
    changeUser() {
      // console.log("selectedUser", this.selectedUser);
    },
    handleButtonClick(childId) {
      let inputElementArray = this.$refs["inputFile" + childId];

      if (inputElementArray && inputElementArray.length > 0) {
        let actualInputElement = inputElementArray[0];
        if (typeof actualInputElement.click === "function") {
          actualInputElement.click();
        } else {
          console.error("El elemento no tiene un método click");
        }
      } else {
        console.error("El elemento no se encontró");
      }
    },
    handleFileChange(event, userId) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (userId) {
            // Caso para los hijos
            this.$set(this.imagePreviews, userId, e.target.result);
            this.uploadImage(e.target.result, userId);
          } else {
            // Caso para la imagen principal
            this.imagePreview = e.target.result;
            this.uploadImage(e.target.result, this.userData.id);
          }
        };
        reader.readAsDataURL(file);
      }
    },

    async uploadImage(base64Image, userId) {
      this.loading = true;
      await this.$store
        .dispatch("user/uploadAvatar", {
          userId: userId,
          data: { avatar: base64Image },
        })
        .then(() => {
          this.message(
            "Subida de imagen",
            "checkCircleIcon",
            "success",
            "Cargada con exito"
          );
          this.loading = false;
        })
        .catch((res) => {
          this.imagePreview = this.userData.avatar;
          this.imagePreviews = this.userData.childs.map((child) => {
            return { [child.id]: child.avatar };
          });
          this.message(
            "Subida de imagen",
            "AlertCircleIcon",
            "danger",
            res.error || "Falló en la carga"
          );
          this.loading = false;
        });
    },
    modalRestrictProducts() {
      if (this.childs.length === 0) {
        this.message(
          "Restricción de productos",
          "AlertCircleIcon",
          "danger",
          "Debe tener al menos un hijo"
        );
        return;
      }
      this.showModalRestrictProducts = true;
    },
    modalAddObservation() {
      if (this.userData.observation !== null) {
        this.isEditObservation = true;
        this.observation = this.userData.observation;
      }
      this.showModalAddObservation = true;
    },
    addObservation() {
      this.loading = true;
      this.$store
        .dispatch("user/addObservation", {
          userId: this.$route.params.id,
          data: { observation: this.observation },
        })
        .then((res) => {
          this.loading = false;
          this.showModalAddObservation = false;
          this.observation = "";
          this.userData.observation = res.observation;
          this.message("Observación", "checkCircleIcon", "success", "Agregada con exito");
        })
        .catch(() => {
          this.message("Observación", "AlertCircleIcon", "danger", "Falló en la carga");
          this.loading = false;
        });
    },
    async addProduct(product) {
      const productExists = this.products.some((p) => p.id === product.id);

      if (!productExists) {
        this.products.unshift(product);
      }

      this.productsNew.splice(this.productsNew.indexOf(product), 1);

      this.loading = true;
      await this.$store
        .dispatch("user/restrictProducts", {
          data: {
            action: "add",
            userIds: this.selectedChildId,
            productIds: [product.id],
          },
        })
        .then((res) => {
          this.loading = false;
          this.searchProductData.product = null;
          // this.showModalRestrictProducts = false;
          // this.productsNew = [];

          this.message(
            "Restricción de productos",
            "checkCircleIcon",
            "success",
            "Restringidos con exito"
          );
        })
        .catch(() => {
          this.message(
            "Restricción de productos",
            "AlertCircleIcon",
            "danger",
            "Falló en la restricción"
          );
          this.loading = false;
        });
    },
    async deleteProduct(product) {
      const index = this.products.findIndex((p) => p.id === product.id);
      if (index !== -1) {
        this.products.splice(index, 1);
      }

      this.loading = true;
      await this.$store
        .dispatch("user/restrictProducts", {
          data: {
            action: "remove",
            userIds: this.selectedChildId,
            productIds: [product.id],
          },
        })
        .then((res) => {
          this.loading = false;
          // this.showModalRestrictProducts = false;
          // this.productsNew = [];

          this.message(
            "Restricción de productos",
            "checkCircleIcon",
            "success",
            "Restringidos con exito"
          );
        })
        .catch(() => {
          this.message(
            "Restricción de productos",
            "AlertCircleIcon",
            "danger",
            "Falló en la restricción"
          );
          this.loading = false;
        });
    },

    async handleCheckboxChange(childSelectedArray, childId) {
      const isChecked = childSelectedArray;

      if (isChecked) {
        // El checkbox ha sido seleccionado
        await this.$store
          .dispatch("user/handleCheckboxChange", {
            childId: childId,
          })
          .then((res) => {
            this.loading = false;
            this.products = res;
          })
          .catch(() => {
            this.message(
              "Busquedad",
              "AlertCircleIcon",
              "danger",
              "Falló en la busqueda"
            );
            this.loading = false;
          });
      } else {
        // El checkbox ha sido deseleccionado
        this.products = [];
      }
    },
    async searchProduct() {
      if (!this.selectedChildId) {
        this.message(
          "Restricción de productos",
          "AlertCircleIcon",
          "danger",
          "Debe seleccionar al menos un hijo"
        );
        return;
      }
      this.loading = true;
      await this.$store
        .dispatch("user/searchProduct", {
          userIds: this.selectedChildId,
          search: this.searchProductData.product,
        })
        .then((res) => {
          this.productsNew = res;
          this.loading = false;
        })
        .catch((res) => {
          this.message(
            "Restricción de productos",
            "AlertCircleIcon",
            "danger",
            res.response.data.message
          );
          this.loading = false;
        });
    },
    async saveMergeChilds(userId) {
      await this.$store
        .dispatch("user/mergeChildsSave", {
          userId: userId,
          data: { childIds: this.childs2.map((child) => child.id) },
        })
        .then(async () => {
          this.loading = false;
          this.showModal = false;
          const userData = await this.$store.dispatch("user/showUser", userId);
          this.childs = userData.childs;
        })
        .catch(() => {
          this.message("Busquedad", "AlertCircleIcon", "danger", "Falló en la busqueda");
          this.loading = false;
        });
    },
    disabledBeforeToday(date) {
      const today = new Date();
      return date >= new Date(today.getTime());
    },
    close() {
      this.showModal = false;
      this.childs = [];
    },
    message(message, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: message,
          icon: icon,
          variant: variant,
          text: text,
        },
      });
    },
  },
};
</script>

<style>
.productsRow {
  height: 350px;
  width: 100%;
  overflow-y: scroll;
}
</style>
