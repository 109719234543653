<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="showUser == undefined && !userDataLoading">
      <h4 class="alert-heading">Error al cargar los datos</h4>
      <div class="alert-body">
        No se encontró ningún usuario con esta identificación de usuario. Chequ
        <b-link class="alert-link" :to="{ name: 'users-todos' }">
          Lista de usuarios
        </b-link>
        para otros usuarios.
      </div>
    </b-alert>

    <b-overlay
      :show="userDataLoading"
      rounded
      responsive
      variant="transparent"
      opacity="0.33"
      blur="2px"
    >
      <template v-if="showUser && !userDataLoading">
        <b-tabs pills>
          <!-- Perfil -->
          <b-tab title="Perfil" active>
            <b-row>
              <b-col cols="12" xl="8" lg="8" md="7">
                <user-view-user-info-card :user-data="showUser" />
              </b-col>
              <b-col cols="12" xl="4" lg="4" md="5">
                <user-view-user-wallet-info :user-data="showUser" />
              </b-col>
            </b-row>
          </b-tab>

          <!-- Hijos -->
          <b-tab title="Hijos" v-if="showUser.typeUser == 'representative' || showUser.typeUser == 'teacher'">
            <Children :childsData="showUser.childs" :userData="showUser" />
          </b-tab>      
        </b-tabs>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
  BAlert,
  BTab,
  BTabs,
  BCardText,
} from "bootstrap-vue";
import { formatDateTimeHumane } from "@core/utils/filter.js";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import { ref, computed, onMounted, nextTick } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";

// component for user info card
import UserViewUserInfoCard from "./UserViewUserInfoCard.vue";
// user-view-user-wallet-info
import UserViewUserWalletInfo from "./UserViewUserWalletInfo.vue";

import Children from "./Children.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BAlert,
    BTab,
    BTabs,
    BCardText,

    // Librery components
    vSelect,

    // Custom components
    UserViewUserInfoCard,
    UserViewUserWalletInfo,
    Children,
  },
  filters: {
    formatDateTimeHumane,
    avatarText,
  },
  computed: {
    showUser() {
      return this.$store.state.user.showUser;
    },
  },
  setup(props, context) {
    // VARIABLES
    const idUser = router.currentRoute.params.id;
    const userData = ref(null);
    const userDataLoading = ref(true);

    // METHODS
    const getUser = async () => {
      try {
        userDataLoading.value = true;
        await store.dispatch("user/showUser", idUser);

        store.commit('metaPage/setPageTitle', `${store.state.user.showUser.name} ${store.state.user.showUser.last_name}`);
      } catch (e) {
        if (e.response.status == 403) {
        }
      } finally {
        userDataLoading.value = false;
      }
    };

    //  LIFECYCLE HOOKS
    onMounted(() => {
      getUser();
    });

    // RETURN
    return {
      userData,
      userDataLoading,
    };
  },
};
</script>

<style></style>
